/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from 'react-router-dom'
import { sponsorStatisticsQuery } from '@EntitySauce/Queries'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import useSponsorAttendees from '@Hooks/useSponsorAttendees'
import { entitySauce } from '@State'
import theme from '@Theme'
/* eslint-disable-next-line no-restricted-imports -- TODO refactor/migrate to using date-fns-tz */
import moment from 'moment-timezone'
import { find, indexBy, prop, propEq } from 'ramda'

import { EventParamsTypes } from '../../Types'
import { Attendee, Sponsor } from '../Types'
import useSponsor from '../useSponsor'

import { AttendeeStat, MeetingsSummary, SponsorMeeting } from './Types'
export * from './Types'

const useSponsorStatistics = () => {
  const params = useParams<EventParamsTypes>()
  const { eventId, sponsorId } = params
  const { selectedEvent: event } = useSelectedEvent()

  const { data, ...rest } = entitySauce.useGet(sponsorStatisticsQuery(
    eventId,
    sponsorId
  ), { staleTime: 50000, refetchOnWindowFocus: false, enabled: !!eventId && !!sponsorId })

  const { attendees: sponsorAttendees } = useSponsorAttendees()
  const sponsorStatistics = data?.data ?? []
  const { sponsor } = useSponsor()
  const meetingSummary: MeetingsSummary[] = getMeetingsSummary(sponsorStatistics)
  const sponsorMeetings: SponsorMeeting[] = getSponsorMeetings(sponsorStatistics, sponsor)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const attendeeStats = (sponsorStatistics[0]?.attendeeStats ?? []).map((stat) => {
    return {
      id: stat.id,
      ...stat.user,
      name: [stat.user?.firstName, stat.user?.lastName].filter(Boolean).join(' '),
      userId: stat.user?.id,
      joinedAt: moment.tz(sponsorAttendees.find((attendee: { id: string }) => attendee.id === stat.id)?.joinedAt, event?.timeZone).format('YYYY-MM-DD HH:mm'),
      ...stat,
      sponsorRepAddedAt: moment.tz(stat?.sponsorRepAddedAt, event?.timeZone).format('YYYY-MM-DD HH:mm'),
      declinedMeetingsWhereChatClosedCount: stat.declinedMeetingsCount - stat.declinedMeetingsWhereChatOpenCount
    }
  })

  const representativesIdsList = attendeeStats.map((rep: { user: { id: any } }) => rep.user?.id)

  return { sponsorStatistics: sponsorStatistics?.[0] ?? [], attendeeStats, meetingSummary, sponsorMeetings, representativesIdsList, ...rest }
}

export default useSponsorStatistics

const getMeetingsSummary = (sponsorStatistics: any) => {
  const {
    totalReceivedMeetingsCount,
    totalRequestedMeetingsCount,
    receivedAcceptedMeetingsCount,
    requestedAcceptedMeetingsCount,
    receivedPendingMeetingsCount,
    requestedPendingMeetingsCount,
    receivedDeclinedMeetingsCount,
    requestedDeclinedMeetingsCount,
    receivedCancelledMeetingsCount,
    requestedCancelledMeetingsCount,
    receivedChatMessagesCount,
    requestedChatMessagesCount,
    openChatRequestsAnsweredCount,
    openChatRequestsConvertedCount,
    openChatRequestsCount,
    receivedAnsweredOpenChatCount,
    receivedPendingOpenChatCount,
    receivedConvertedOpenChatCount,
    requestedConvertedOpenChatCount,
    requestedPendingOpenChatCount,
    requestedAnsweredOpenChatCount
  } = sponsorStatistics?.[0] ?? {}

  const meetingSummary = [{
    key: 'Total meetings',
    total: totalReceivedMeetingsCount + totalRequestedMeetingsCount,
    inbound: totalReceivedMeetingsCount,
    outbound: totalRequestedMeetingsCount
  }, {
    key: 'Meetings: Accepted',
    label: <span>
      {'Meetings: '}
      {<span style={{ color: theme.colors.primary }}>{'Accepted'}</span>}
    </span>,
    total: receivedAcceptedMeetingsCount + requestedAcceptedMeetingsCount,
    inbound: receivedAcceptedMeetingsCount,
    outbound: requestedAcceptedMeetingsCount
  }, {
    key: 'Meetings: Pending',
    label: <span>
      {'Meetings: '}
      {<span style={{ color: theme.colors.infoOrange }}>{'Pending'}</span>}
    </span>,
    total: receivedPendingMeetingsCount + requestedPendingMeetingsCount,
    inbound: receivedPendingMeetingsCount,
    outbound: requestedPendingMeetingsCount
  }, {
    key: 'Meetings: Declined',
    label: <span>
      {'Meetings: '}
      {<span style={{ color: theme.colors.dangerRed }}>{'Declined'}</span>}
    </span>,
    total: receivedDeclinedMeetingsCount + requestedDeclinedMeetingsCount,
    inbound: receivedDeclinedMeetingsCount,
    outbound: requestedDeclinedMeetingsCount
  }, {
    key: 'Meetings: Cancelled',
    label: <span>
      {'Meetings: '}
      {<span style={{ color: theme.colors.dangerRed }}>{'Cancelled'}</span>}
    </span>,
    total: receivedCancelledMeetingsCount + requestedCancelledMeetingsCount,
    inbound: receivedCancelledMeetingsCount,
    outbound: requestedCancelledMeetingsCount
  }, {
    key: 'Open chat: Answered',
    label: <span>
      {'Open chat: '}
      {<span style={{ color: theme.colors.primary }}>{'Answered'}</span>}
    </span>,
    total: openChatRequestsAnsweredCount,
    inbound: receivedAnsweredOpenChatCount,
    outbound: requestedAnsweredOpenChatCount
  }, {
    key: 'Open chat: Pending',
    label: <span>
      {'Open chat: '}
      {<span style={{ color: theme.colors.infoOrange }}>{'Pending'}</span>}
    </span>,
    total: openChatRequestsCount,
    inbound: receivedPendingOpenChatCount,
    outbound: requestedPendingOpenChatCount
  }, {
    key: 'Open chat: Converted to meeting',
    label: <span>
      {'Open chat: '}
      {<span style={{ color: theme.colors.primary }}>{'Converted to meeting'}</span>}
    </span>,
    total: openChatRequestsConvertedCount,
    inbound: receivedConvertedOpenChatCount,
    outbound: requestedConvertedOpenChatCount
  }, {
    key: 'Total Messages',
    total: receivedChatMessagesCount + requestedChatMessagesCount,
    inbound: receivedChatMessagesCount,
    outbound: requestedChatMessagesCount
  }]

  return (meetingSummary)
}

const getSponsorMeetings = (sponsorStatistics: [{id: any, attendeeStats: any}], sponsor: Sponsor) => {
  const attendees = sponsor?.attendees ?? []
  const attendeeIds = attendees.map((attendee: any) => attendee.id) || []
  const attendeeStats: AttendeeStat[] = sponsorStatistics?.[0]?.attendeeStats ?? []

  const attendeeStatsById = indexBy(prop('id'), attendeeStats)

  const result = attendeeIds.reduce((result: any, id: any) => {
    const representative = find(propEq('id', id))(attendees) as Attendee
    const stats = attendeeStatsById[id] || {}
    const meetings = stats.meetings || []
    const getLocation = (table: { tableIndex: any }, status: string) => {
      if (!table) {
        if ((status === 'pending') || (status === 'cancelled') || (status === 'rejected')) {
          return ''
        }
        return 'Custom'
      }

      return table.tableIndex
    }

    const rows = meetings.map((meeting: { currentStatus: { issuer: { id: any }, state: string }, users: any, timeslot: { startTime: any }, table: { tableIndex: any }, chatMessagesCount: any }, index: any) => {
      const issuerId = meeting?.currentStatus?.issuer?.id
      const issuer = find(propEq('id', issuerId), meeting?.users)
      const requester = issuer
      const receiver = find(function(o: any) { return o.id !== issuerId }, meeting?.users)
      const direction = (requester?.id === stats.userId) ? 'Outbound' : 'Inbound'
      const other = (direction === 'Outbound') ? receiver ?? {} : requester ?? {}
      const startTime = meeting.timeslot && meeting.timeslot.startTime

      return {
        id: `${id}-${index}`,
        attendeeId: other.id,
        representative: `${representative?.user?.firstName ?? ''} ${representative?.user?.lastName ?? ''}`,
        other: `${other?.firstName} ${other?.lastName}`,
        company: other.companyName,
        title: other.companyTitle,
        date: startTime ? moment.utc(startTime).format('YYYY-MM-DD HH:mm') : 'Custom',
        status: meeting?.currentStatus?.state,
        location: getLocation(meeting.table, meeting?.currentStatus?.state),
        direction,
        messages: meeting.chatMessagesCount
      }
    })

    result.push(...rows)
    return result
  }, [])
  return result
}

import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { AuthApi } from '@EntitySauce/Api'
import PropTypes from 'prop-types'
import snakecaseKeys from 'snakecase-keys'

import { AUTH_STATE } from './Constants'

const authContext = createContext()

export function AuthProvider({ children }) {
  const auth = useProvideAuth()

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}

export function useAuth() {
  return useContext(authContext)
}

function useProvideAuth(opts) {
  const [authState, setAuthState] = useState(window.localStorage.getItem('authHeaders')
    ? AUTH_STATE.AUTHENTICATED
    : AUTH_STATE.NOT_AUTHENTICATED)

  const signIn = useCallback(async({ email, password, onSuccess, onError }) => {
    try {
      setAuthState(AUTH_STATE.FETCHING)

      const res = await AuthApi.signIn({ email, password })

      window.localStorage.setItem('email', email)

      setAuthState(AUTH_STATE.AUTHENTICATED)
      onSuccess(res)
    } catch (res) {
      setAuthState(AUTH_STATE.AUTHENTICATION_ERROR)
      onError(res.data?.errors?.[0]?.title || 'Something went wrong', res)
    }
  }, [])

  const signUp = useCallback(async({ user, hcaptchaToken, onSuccess, onError }) => {
    try {
      setAuthState(AUTH_STATE.FETCHING)

      const res = await AuthApi.createAccount(snakecaseKeys({ user, hcaptchaToken }))

      window.localStorage.setItem('email', user.email)

      setAuthState(AUTH_STATE.AUTHENTICATED)
      onSuccess(res)
    } catch (res) {
      setAuthState(AUTH_STATE.AUTHENTICATION_ERROR)
      onError(res.data?.errors?.[0]?.title || 'Something went wrong', res)
    }
  }, [])

  const signOut = useCallback(async({ onSuccess, onError }) => {
    try {
      setAuthState(AUTH_STATE.FETCHING)

      const res = await AuthApi.signOut()

      if (!res.ok) {
        throw new Error(res.data?.errors?.[0]?.title)
      }

      window.localStorage.removeItem('authHeaders')
      setAuthState(AUTH_STATE.NOT_AUTHENTICATED)
      onSuccess(res)
    } catch (error) {
      setAuthState(AUTH_STATE.AUTHENTICATION_ERROR)
      onError(error?.message || 'Something went wrong', error)
    }
  }, [])

  const getProviderByEmail = useCallback(async({ email, token }) => {
    try {
      setAuthState(AUTH_STATE.FETCHING)

      const res = await AuthApi.getUserAuthProvider({ email, token })

      if (!res.ok) {
        throw new Error(res.data?.errors?.[0]?.title)
      }

      setAuthState(AUTH_STATE.NOT_AUTHENTICATED)

      return res.data
    } catch (error) {
      opts?.onGetProviderByEmailOnError(error?.message || 'Something went wrong', error)
    }

    setAuthState(AUTH_STATE.NOT_AUTHENTICATED)
  }, [opts])

  useEffect(function setInitialState() {
    if (window.localStorage.getItem('authHeaders')) {
      setAuthState(AUTH_STATE.AUTHENTICATED)
    } else {
      setAuthState(AUTH_STATE.NOT_AUTHENTICATED)
    }
  }, [])

  return {
    authState,
    signIn,
    signUp,
    signOut,
    getProviderByEmail,
    setAuthState
  }
}

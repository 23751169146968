import React from 'react'
import useSelectedEvent from '@Hooks/useSelectedEvent'
import useSponsor from '@Hooks/useSponsor'
import useUser from '@Hooks/useUser'
import Intercom, { hide, show, trackEvent, update } from '@intercom/messenger-js-sdk'

function useSupportChat() {
  const { user } = useUser()
  const { sponsor } = useSponsor()
  const { selectedEvent: event } = useSelectedEvent()

  function showChat() {
    trackEvent('Chat opened (Company Portal)', {
      app: 'Company Portal',
      event_name: event?.name,
      event_id: event?.id,
      sponsor_name: sponsor?.name,
      sponsor_id: sponsor?.id,
      view: window.location.href
    })

    update({
      last_app: 'Company Portal',
      last_app_name: '',
      last_platform: 'browser',
      last_screen: window.location.href,
      last_event: event?.name,
      last_event_id: event?.id
    })

    show()
  }

  React.useEffect(() => {
    if (user?.id) {
      Intercom({
        app_id: import.meta.env.VITE_APP_INTERCOM_ID,
        region: 'eu',
        user_id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        user_hash: user.intercomUserHash,
        hide_default_launcher: true,
        avatar: {
          type: 'avatar',
          image_url: user.imageUrl
        }
      })
    }
  }, [user])

  return { show: showChat, hide }
}

export default useSupportChat

/* eslint-disable @typescript-eslint/no-explicit-any */
import { isUsingCypress } from '@Lib/Util'
import { Modal } from 'antd'
import { omit } from 'ramda'

import { createApiInterface, Frisbee } from '../helpers'

import { headerDefinitions } from './ApiHeaders'

const api = new Frisbee({
  baseURI: import.meta.env.VITE_API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'content-type': 'application/json; charset=utf-8',
    ...headerDefinitions.v4.headers
  },
  mode: 'cors',
  stringify: {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    format: 'RFC1738'
  }
})

api.interceptor.register({
  requestError: function(response: any) {
    console.log(response)
    return Promise.resolve({
      data: null,
      ok: false
    })
  },
  request: async function(path: any, options: any) {
    // If we don't have auth headers, try to get them from the local storage.
    if (!api.opts?.headers?.['access-token']) {
      // We don't have auth headers, try to get them from the window.localStorage.

      try {
        const authHeadersString = window.localStorage.getItem('authHeaders')
        const authHeadersObj = JSON.parse(authHeadersString || '{}')

        // It's possible that this interceptor does not have options
        // argument. This might be a bug in frisbee.
        // We have to have these two different strategies to ensure we have
        // the headers set.
        if (!options) {
          await api.setOptions({
            headers: {
              ...api.opts.headers,
              ...authHeadersObj
            }
          })
        } else {
          options.headers = {
            ...options.headers,
            ...authHeadersObj
          }
        }
      } catch (error) {
        console.error('Error getting auth headers from local storage', error)
      }
    }

    return [path, options]
  },
  response: async function(response: { originalResponse: any, body: any, status: any }) {
    const { originalResponse, body } = response

    const authHeaders = {
      'access-token': originalResponse.headers.get('access-token'),
      client: originalResponse.headers.get('client'),
      uid: originalResponse.headers.get('uid')
    }

    if (authHeaders['access-token'] !== '' && authHeaders['access-token']) {
      // Set the auth headers for the next request
      await api.setOptions({
        headers: {
          ...api.opts.headers,
          ...authHeaders
        }
      })

      // Store the auth headers in the local storage
      // so they can be used after app restart
      window.localStorage.setItem('authHeaders', JSON.stringify(authHeaders))
    }

    if (response.status === 401 && body?.errors?.[0]?.title === 'Authentication required.') {
      if (isUsingCypress) {
        // For some reason the token is invalid in Cypress tests.
        // Preferably handle this in Cypress's intercepts.
        console.error(`Session had expired, consequent requests will fail. Token: ${window.localStorage.getItem('authHeaders')}`)
        window.alert(`Session had expired, consequent requests will fail. Token: ${window.localStorage.getItem('authHeaders')}`)

        return
      }

      window.localStorage.removeItem('authHeaders')

      window.location.assign(`/login?from=${encodeURIComponent(window.location.pathname)}`)
    }

    if (response.status === 403 && body?.errors?.[0]?.code === 'user_not_found') {
      window.localStorage.removeItem('authHeaders')

      Modal.info({
        title: 'No account found',
        content: 'Your account cannot be found. Please log in again.',
        onOk: () => {
          window.location.assign(`/login?from=${encodeURIComponent(window.location.pathname)}`)
        },
        okText: 'Log in',
        closable: false
      })
    }

    return {
      data: body,
      ok: originalResponse.ok,
      status: originalResponse.status,
      url: originalResponse.url
    }
  },
  responseError: function() {
    return Promise.resolve({
      data: null,
      ok: false
    })
  }
})

const methods = {
  delete: (url: any, data: any, headers: any) => api.delete(url, { body: data, ...headers }),
  get: (url: any, data: any, headers: any) => api.get(url, { body: data, ...headers }),
  getResponseUrl: (response: { url: any }) => response.url,
  patch: (url: any, data: any, headers: any) => api.patch(url, { body: data, ...headers }),
  post: (url: any, data: any, headers: any) => api.post(url, { body: data, ...headers }),
  put: (url: any, data: any, headers: any) => api.patch(url, { params: data, ...headers }),
  remove: (url: any, data: any, headers: any) => api.delete(url, { body: data, ...headers }),
  removeAuthHeader: () => api.jwt(),
  setAuthHeader: (token: any) => api.jwt(token),
  setAuthToken: (token: any) => api.jwt(token)
}

const paramTransform = (url: any, params: any, config: any) => [url, params, config]

function responseTransform(response: { ok: any }) {
  if (!response.ok) {
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

const Api = createApiInterface(methods, paramTransform, responseTransform)

export const uploadEventImage = (eventId: number, image: string) => {
  return Api.post(`/admin_panel/events/${eventId}/content_images`, { content_image: { image } })
}

const getUserAuthProvider = (payload: any) => {
  return Api.get('me/user/provider', omit(['token'], payload), {
    headers: {
      ...headerDefinitions.v4.headers,
      ...(payload.token && { Authorization: payload.token })
    }
  })
}

const setPassword = (payload: any) => {
  return Api.patch('me/user/update_password', { user: { ...payload } }, {
    headers: {
      ...headerDefinitions.v4.headers, 'cache-control': 'no-store'
    }
  })
}

const signIn = (payload: any) => {
  return Api.post('/auth/sign_in', payload, {
    headers: {
      ...headerDefinitions.v4.headers, 'cache-control': 'no-store'
    }
  })
}

const signOut = () => {
  return Api.delete('/auth/sign_out', null, {
    headers: {
      ...headerDefinitions.v4.headers, 'cache-control': 'no-store'
    }
  })
}

const createAccount = (payload: any) => {
  return Api.post('me/user', payload, {
    headers: {
      ...headerDefinitions.v4.headers, 'cache-control': 'no-store'
    }
  })
}

export const AuthApi = {
  createAccount,
  getUserAuthProvider,
  setPassword,
  signIn,
  signOut
}

export default Api

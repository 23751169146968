import { useParams, useRouteMatch } from 'react-router-dom'
import { find, propEq } from 'ramda'
import { EventParamsTypes } from 'src/Types'

import useEvents from './useEvents/useEvents'

const useSelectedEvent = () => {
  const match = useRouteMatch<EventParamsTypes>('/events/:eventId/sponsor/:sponsorId')
  const params = useParams<EventParamsTypes>()
  const { eventId } = params
  const { events } = useEvents()

  const selectedEvent: EventParamsTypes = find(propEq('id', eventId || match?.params.eventId))(events) || {}

  return { selectedEvent }
}

export default useSelectedEvent
